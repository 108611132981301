import { createApp } from "petite-vue";
import Swiper from "swiper";
import {Keyboard, Navigation, Pagination, Autoplay} from "swiper/modules";
import SimpleLightbox from "simple-lightbox";
import { setCookie } from "./utils/Cookies"


import.meta.glob(['@images/**']);
import.meta.env.DEV && import("@styles/main.sass");

declare global {

    interface Window {
        Alpine: any
    }
}
interface Header {
    opened: boolean;
    handleOpenedHeader(state: boolean): void;
}

const headerEl = document.querySelector('[data-header]');

const headerScope: Header = {
    opened: false,
    handleOpenedHeader(state) {
        document.body.classList.toggle("u-hiddenOverflow", state);
    }
};

if (headerEl) {
    createApp(headerScope).mount(headerEl);
}

//------------------------------------------------------------datacount
(() => {

        const numberItems = document.querySelectorAll<HTMLElement>('.Number-item h3');

        const animateNumber = (element: HTMLElement, finalValue: number) => {
            let currentValue = 0;
            const duration = 2000;
            const increment = finalValue / duration * 10;

            const updateNumber = () => {
                currentValue += increment;
                if (currentValue >= finalValue) {
                    element.textContent = `${Math.floor(finalValue)}+`;
                } else {
                    element.textContent = `${Math.floor(currentValue)}+`;
                    requestAnimationFrame(updateNumber);
                }
            };

            updateNumber();
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const element = entry.target as HTMLElement;
                    const finalValue = parseInt(element.dataset.count || '0', 10);
                    animateNumber(element, finalValue);
                    observer.unobserve(element);
                }
            });
        }, {
            threshold: 0.5
        });

        numberItems.forEach(item => {
            observer.observe(item);
        });
})();


//----------------------------------------------------Video
(() => {

        const playButton = document.getElementById("play-button");
        const videoThumbnail = document.getElementById("video-thumbnail");
        const videoIframe = document.getElementById("video-iframe")?.querySelector("iframe");

        if (playButton && videoThumbnail && videoIframe) {
            playButton.addEventListener("click", function () {
                videoThumbnail.style.display = "none";
                playButton.style.display = "none";
                document.getElementById("video-iframe").style.display = "block";

                let src = videoIframe.getAttribute("src");

                if (src.includes("youtube") || src.includes("youtube-nocookie")) {
                    let videoId = src.split("/embed/")[1]?.split("?")[0];
                    videoIframe.setAttribute("src", `https://www.youtube.com/embed/${videoId}?autoplay=1&enablejsapi=1`);
                }
                else if (src.includes(".mp4")) {
                    let videoTag = document.createElement("video");
                    videoTag.setAttribute("src", src);
                    videoTag.setAttribute("width", "640");
                    videoTag.setAttribute("height", "360");
                    videoTag.setAttribute("controls", "true");
                    videoTag.setAttribute("autoplay", "true");
                    videoTag.setAttribute("muted", "true");
                    videoIframe.parentNode.replaceChild(videoTag, videoIframe);
                }
            });
        }
})();

//---------------------------------------------------- Quick select dropdown
(() => {
    document.addEventListener('click', (event) => {
        const toggleIcon = event.target.closest('.Select-header');
        if (toggleIcon) {
            toggleIcon.parentElement.classList.toggle('active');
        }
    });
})();

//------------------------------------------Education Principles dropdown
(() => {

        document.querySelectorAll('.Principles-item').forEach(item => {
            const header = item.querySelector('.Principles-header') as HTMLElement;
            const headerLink = item.querySelector('.Principles-header span');
            const content = item.querySelector('.Principles-content') as HTMLElement;
            const plusIcon = item.querySelector('.icon-line.plus') as HTMLElement;
            const minusIcon = item.querySelector('.icon-line.minus') as HTMLElement;

            if (header && headerLink && content && plusIcon && minusIcon) {
                minusIcon.style.display = 'none';

                header.addEventListener('click', () => {
                    const isActive = item.classList.toggle('active');

                    if (isActive) {
                        headerLink.innerHTML = `${headerLink.textContent} ${content.innerHTML}`;
                        content.style.display = 'none';
                        plusIcon.style.display = 'none';
                        minusIcon.style.display = 'block';
                    } else {
                        headerLink.innerHTML = headerLink.textContent.split(content.textContent)[0];
                        content.style.display = 'none';
                        plusIcon.style.display = 'block';
                        minusIcon.style.display = 'none';
                    }
                });
            }
        });
})();


//--------------------------------------------------------Swipers
(() => {
    if (document.querySelector("[data-why-swiper]")) {
        new Swiper("[data-why-swiper]", {
            modules: [Navigation, Keyboard, Autoplay],
            slidesPerView: 1,
            slidesPerGroup: 1,
            breakpoints: {
                580: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,

                },
                858: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,

                },
                1124: {
                    slidesPerView: 4,
                    slidesPerGroup: 1,

                },
            },
            spaceBetween: 30,
            autoplay: { delay: 4000 },
            loop: true,
            speed: 500,
            grabCursor: true,
            keyboard: { enabled: true, onlyInViewport: true },
            navigation: { nextEl: ".button-dalsi", prevEl: ".button-predchozi" }
        });
    }

    if (document.querySelector("[data-reference-swiper]")) {
        new Swiper("[data-reference-swiper]", {
            modules: [Navigation, Keyboard, Pagination],
            slidesPerView: 1,
            autoplay: { delay: 5000 },
            loop: true,
            navigation: { nextEl: ".button-next", prevEl: ".button-prev" },
            pagination: { el: ".swiper-pagination", clickable: true }
        });
    }
})();


// --------------------------- Lightbox ---------------------------
(() => {
    const galleries = [
        { selector: ".wp-block-gallery", elements: ".wp-block-gallery .wp-block-image > a" },
        { selector: ".Gallery-images", elements: ".Gallery-images .Gallery-image > a" }
    ];

    galleries.forEach(({ selector, elements }) => {
        if (document.querySelector(selector)) {
            new SimpleLightbox({ elements });
        }
    });
})();


//---------------------------------------------------------------------------Span pro tlačítka
(() => {

        document.querySelectorAll('.wp-element-button').forEach(function (button) {
            if (!button.querySelector('span')) {
                let text = button.textContent.trim();
                button.innerHTML = `<span>${text}</span>`;
            }
        });
})();


//-------------------------------------------------datetime
(() => {

        document.querySelectorAll('.FormFieldDate').forEach(wrapper => {
            wrapper.addEventListener('click', function () {
                const input = this.querySelector('input[type="date"]');
                if (input) {
                    input.showPicker();
                }
            });
        });
})();
//------------------------------------------Span pro tlačítka

(() => {
    function wrapButtonText() {
        document.querySelectorAll('.wp-element-button').forEach(function(button) {
            if (!button.querySelector('span')) {
                let text = button.textContent.trim();
                button.innerHTML = `<span>${text}</span>`;
            }
        });
    }

    wrapButtonText();


    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.type === 'childList') {
                wrapButtonText();
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });

})();

//..................................................................Zpoždění pro animaci

(() => {

    const globalTags = document.querySelectorAll('.GlobalTag');

    const GlobalTagsObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {

                entry.target.classList.add('animate');

                GlobalTagsObserver.unobserve(entry.target);
            }
        });
    }, { threshold: 0.1 });

    globalTags.forEach(tag => {
        GlobalTagsObserver.observe(tag);
    });
})();

//-----------------------------------------jenom jeden checkbox

(() => {
    const checkboxGroups = document.querySelectorAll('.form-group.oneLine');

    checkboxGroups.forEach((group) => {
        const checkboxes = group.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', function () {
                if (this.checked) {
                    checkboxes.forEach((box) => {
                        if (box !== this) {
                            box.checked = false;
                        }
                    });
                }
            });
        });
    });
})();

//-------------------------------------repeater sourozenci

(() => {
    updateInputValues();

    const repeaterContainer = document.querySelector('.FormRepeater');
    const template = document.querySelector('template');
    let index = 1;

    if (repeaterContainer && template) {
        const addButton = document.querySelector('.FormRepeater-add');

        if (addButton) {
            addButton.addEventListener('click', () => {
                // Clone the template content
                const clone = document.importNode(template.content, true);
                const inputs = clone.querySelectorAll('input');

                inputs.forEach(input => {
                    const name = input.getAttribute('name');
                    if (name) {
                        // Replace 'new' with the current index in the name attribute
                        input.setAttribute('name', name.replace('new', index.toString()));
                    }
                });

                repeaterContainer.insertBefore(clone, addButton.parentElement);
                index++;

                updateInputValues();
            });
        }
    }

//----------------------------------------label na inputech
    function updateInputValues() {
        document.querySelectorAll('input[type="text"], textarea').forEach(function (input) {
            if (input.value.trim() !== "") {
                input.classList.add('has-value');
            } else {
                input.classList.remove('has-value');
            }

            input.addEventListener('input', function () {
                if (input.value.trim() !== "") {
                    input.classList.add('has-value');
                } else {
                    input.classList.remove('has-value');
                }
            });
        });
    }
})();


//-----------------------------------------------------telefonní číslo
(() => {

    const phoneInput = document.querySelector('input[name="contact_phone"]')
    const errorMessagePhone = document.getElementById('phone-error-message')


    phoneInput.addEventListener('input', function (e) {
        const validChars = /^[0-9]*$/
        if (!validChars.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^0-9]/g, '')
        }
        errorMessagePhone.style.display = 'none'
    })

    phoneInput.addEventListener('blur', function () {
        const phonePattern = /^[0-9]{9}$/
        if (!phonePattern.test(phoneInput.value)) {
            errorMessagePhone.textContent = 'Zadejte platné 9místné telefonní číslo bez předvolby'
            errorMessagePhone.style.display = 'block'
        } else {
            errorMessagePhone.style.display = 'none'
        }
    })
})();


// --------------------------------------------------------------- Rodné číslo validation
(() => {

    const rcInput = document.querySelector('input[name="rc_part1"]');
    const rcErrorMessage = document.getElementById('rc-error-message');

    rcInput.addEventListener('keypress', function(event) {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    });

    rcInput.addEventListener('blur', function() {
        const rcValue = rcInput.value;

        if (rcValue.length !== 9 && rcValue.length !== 10) {
            rcErrorMessage.textContent = 'Rodné číslo musí mít 9 nebo 10 číslic.';
            rcErrorMessage.style.display = 'block';
            return;
        }

        if (rcValue.length === 10) {
            const numberRc = parseInt(rcValue, 10);
            if (numberRc % 11 !== 0) {
                rcErrorMessage.textContent = 'Rodné číslo není správné. Zkontrolujte prosím překlepy.';
                rcErrorMessage.style.display = 'block';
                return;
            }
        }

        rcErrorMessage.style.display = 'none';
    });
})();


// --------------------------------------------------------------- Required fields validation
(() => {
    const form = document.getElementById('myForm');
    const requiredFields = document.querySelectorAll('[required]');

    function showErrorMessage(field) {
        let errorMessage = field.parentNode.querySelector('.error-message');

        if (!errorMessage) {
            errorMessage = document.createElement('div');
            errorMessage.className = 'error-message';
            errorMessage.style.color = 'red';
            field.parentNode.appendChild(errorMessage);
        }

        errorMessage.textContent = 'Toto pole je povinné.';
        errorMessage.style.display = 'block';
    }

    function hideErrorMessage(field) {
        let errorMessage = field.parentNode.querySelector('.error-message');
        if (errorMessage) {
            errorMessage.style.display = 'none';
        }
    }

    form.addEventListener('submit', function (event) {
        let isValid = true;
        let firstInvalidField = null;

        requiredFields.forEach(function (field) {
            if (!field.checkValidity()) {
                showErrorMessage(field);
                isValid = false;

                if (!firstInvalidField) {
                    firstInvalidField = field;
                }
            } else {
                hideErrorMessage(field);
            }
        });

        if (!isValid) {
            event.preventDefault();
            if (firstInvalidField) {
                firstInvalidField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                firstInvalidField.focus();
            }
        }
    });

    requiredFields.forEach(function (field) {
        field.addEventListener('blur', function () {
            if (!field.checkValidity()) {
                showErrorMessage(field);
            } else {
                hideErrorMessage(field);
            }
        });
    });

    requiredFields.forEach(function (field) {
        field.addEventListener('invalid', function (event) {
            showErrorMessage(field);
        });
    });
})();


document.addEventListener("DOMContentLoaded", function () {
    const button = document.querySelector('.AppInfoBar-closer');

    if (!button) return;

    button.addEventListener('click', function () {
        const infoBar = this.closest('.AppInfoBar');
        const key = this.dataset.key ?? '0';

        setCookie(`AppInfoBar-${key}`, 'closed', 365);

        if (infoBar) {
            infoBar.remove();
        }
    });
});

